export function hide(element) {
  element.style.setProperty("display", "none");
}

export function show(element) {
  element.style.setProperty("display", "inline-block", "important")
}

export function showBlock(element) {
  element.style.setProperty("display", "block", "important")
}
