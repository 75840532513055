// JS and config file for FontAwesome
import "@fortawesome/fontawesome-free/js/all";
import { config } from "@fortawesome/fontawesome-svg-core";
// Turbo-Rails framework for SPA-like navigation
import * as Turbo from "@hotwired/turbo";
// Stimulus framework for easily adding javascript to the DOM
import { Application } from "@hotwired/stimulus";
// Helper for registering stimulus controllers with vite
import { registerControllers } from "stimulus-vite-helpers";
// Collection of functions using bootstrap initialized when DOM is ready
import "../javascript/bootstrap";

/**
 * Font Awesome Configuration
 * */
config.autoAddCss = false; // Prevent FA from dynamically adding its css since we added it manually in application.scss
FontAwesome.config.mutateApproach = "sync"; // Ensures icon CSS is loaded immediately before attempting to render icons

/**
 * Turbo Configuration
 * */
Turbo.start(); // Initialize Turbo framework

/**
 * Stimulus Configuration
 * */
const stimulusApp = Application.start(); // Initialize Stimulus app
stimulusApp.debug = false; // Enable debugging mode
window.Stimulus = stimulusApp; // Bind Stimulus to the window object
// Import all the stimulus controllers
// TODO: Look into dynamic loading of controllers so their not all imported at once
const controllers = import.meta.glob("../javascript/controllers/**/*_controller.js", { eager: true });
const componentControllers = import.meta.glob("../../views/components/**/*_controller.js", { eager: true });

registerControllers(stimulusApp, { ...controllers, ...componentControllers }); // Register the found controllers with the Stimulus app
