import { Controller } from "@hotwired/stimulus";

// References Action Center address search stage controller
export default class extends Controller {
  static targets = ["searchAddress"];

  connect() {
    const searchAddressBtn = this.searchAddressTarget;
    searchAddressBtn?.addEventListener("click", () => window.open(searchAddressBtn.dataset.searchUrl, "_blank"));
  }
}
