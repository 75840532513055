import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["newReferenceTemplate", "newReferenceTarget"];

  static values = {
    wrapperSelector: {
      type: String,
      default: ".templated-field-wrapper",
    },
  };

  connect() {
    this.referenceCount = this.element.querySelectorAll(".templated-field-wrapper").length;
  }

  add(_e) {
    // Count the exisiting references
    const existingReferencesCount = this.element.querySelectorAll(".templated-field-wrapper").length;
    // Replace inner html __NEW_RECORD__ place holder with correct index
    let content = this.newReferenceTemplateTarget.innerHTML.replace(/__NEW_RECORD__/g, this.referenceCount);
    // Replace inner html __NEW_RECORD_SHOW_INDEX__ index offset by 1
    content = content.replace(/__NEW_RECORD_SHOW_INDEX__/g, existingReferencesCount + 1);
    this.newReferenceTargetTarget.insertAdjacentHTML("beforebegin", content);
    this.referenceCount += 1;
  }

  remove(e) {
    // Spans that hold index of reference shown in view to customer (ie reference 1, reference 2)
    const referenceShowIndexSpans = this.element.querySelectorAll("[data-reference-show-index]");
    // Parent container of removed reference
    const wrapper = e.target.closest(this.wrapperSelectorValue);
    const wrapperInput = wrapper.querySelector("input"); // Get any input from reference, we just need the name for index
    const indexRegex = /\[(\d+)\]/;
    // Removed reference index parsed to integer
    const deletedIndex = parseInt(indexRegex.exec(wrapperInput.name)[1]);

    // Id input of deleted index
    const deletedIdInput = wrapper.parentElement.querySelector(
      `input[name='order[rent_references_attributes][${deletedIndex}][id]']`,
    );

    // If reference was persisted and the value is present
    if (deletedIdInput && deletedIdInput.value) {
      // Create a destroy input element in the parent element to mark this record for deletion
      const name = `order[rent_references_attributes][${deletedIndex}][_destroy]`;
      const id = `order_rent_references_attributes_${deletedIndex}__destroy`;
      const content = `
        <input id=${id} name=${name} type='hidden' value=1 />
      `;

      // Insert content only if hidden input doesn't exist
      if (wrapper.parentElement.querySelector(`#${id}`) === null) {
        wrapper.parentElement.insertAdjacentHTML("afterbegin", content);
      }
    }

    // Then remove the reference entirely and update the shown indexes for any reference with a greater index
    wrapper.remove();
    referenceShowIndexSpans.forEach((elem) => {
      const showIndex = parseInt(elem.innerHTML);
      if (deletedIndex < showIndex) {
        elem.innerHTML = showIndex - 1;
      }
    });
  }
}
