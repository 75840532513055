import { Controller } from "@hotwired/stimulus";
import { showBlock, hide } from '../../../utils';

// Reference Residence Summary Form controller
export default class extends Controller {

  connect() {
    this.wouldRentAgain = this.element.querySelector('select[name="reference[would_rent_again]"]');
    this.wouldNotRentAgainNoteDiv = this.element.querySelector('#would-not-rent-again-note-div');
    this.wouldNotRentAgainNote = this.element.querySelector('#reference_would_not_rent_again_note');

    this.wouldRentAgain.addEventListener("change", this.syncFormWithWouldRentAgain.bind(this));

    this.syncFormWithWouldRentAgain();
  }

  hide(element) {
    element.style.setProperty("display", "none");
  }

  showBlock(element) {
    element.style.setProperty("display", "block", "important")
  }

  wouldRentAgainEventListener(e) {
    this.syncFormWithWouldRentAgain();
  }

  syncFormWithWouldRentAgain() {
    let value = this.wouldRentAgain.value;
    if (value === 'false') {
      showBlock(this.wouldNotRentAgainNoteDiv);
      this.wouldNotRentAgainNote.required = true;
    } else {
      hide(this.wouldNotRentAgainNoteDiv);
      this.wouldNotRentAgainNote.required = false;
    }
  }
}
