import { Controller } from "@hotwired/stimulus";

// Reference Resident Confirmation Form controller
export default class extends Controller {

  connect() {
    this.applicantStatus = this.element.querySelector('select[name="reference[applicant_status]"]');
    this.landlordRelationshipDiv = this.element.querySelector('#landlord-relationship-div');
    this.landlordRelationship = this.element.querySelector('select[name="reference[landlord_relationship]"]');
    this.isRentAgreementDiv = this.element.querySelector('#is-rent-agreement-div');
    this.isRentAgreement = this.element.querySelector('select[name="reference[paid_rent]"]');
    this.adjustAddressDiv = this.element.querySelector("#adjust-address-fields");
    this.adjustAddressBtn = this.element.querySelector("#adjust-address-visibility-on");

    this.adjustAddressBtn.addEventListener("click", this.adjustAddressEventListener.bind(this));
    this.applicantStatus.addEventListener("change", this.applicantStatusEventListener.bind(this));
    this.landlordRelationship.addEventListener("change", this.landlordRelationshipEventListener.bind(this));

    this.syncFormWithResidentStatus();
    this.syncFormWithLandlordRelationship();
  }

  hide(element) {
    element.style.setProperty("display", "none");
  }

  show(element) {
    element.style.setProperty("display", "inline-block", "important")
  }

  showBlock(element) {
    element.style.setProperty("display", "block", "important")
  }

  adjustAddressEventListener(_e) {
    this.showBlock(this.adjustAddressDiv);
  }

  applicantStatusEventListener(e) {
    this.syncFormWithResidentStatus();
    this.syncFormWithLandlordRelationship();
  }

  landlordRelationshipEventListener(e) {
    this.syncFormWithResidentStatus();
    this.syncFormWithLandlordRelationship();
  }

  disconnect() {
    this.applicantStatus.removeEventListener("change", (e) =>
      this.syncFormWithResidentStatus()
    );
  }

  syncFormWithResidentStatus() {
    let value = this.applicantStatus.value;
    if (value === 'UNKNOWN_RESIDENT' || value === 'LOST_RECORDS') {
      this.hide(this.landlordRelationshipDiv);
      this.hide(this.adjustAddressBtn);
      this.hide(this.adjustAddressDiv);
      this.landlordRelationship.required = false;
      return;
    }

    this.show(this.adjustAddressBtn);
    if (!value) return;

    this.showBlock(this.landlordRelationshipDiv);
    this.landlordRelationship.required = true;
  }

  syncFormWithLandlordRelationship() {
    let residentStatusValue = this.applicantStatus.value;
    let value = this.landlordRelationship.value;
    if (!value || value === 'SELF' || !residentStatusValue || residentStatusValue == 'UNKNOWN_RESIDENT'
      || residentStatusValue === 'LOST_RECORDS') {
      this.hide(this.isRentAgreementDiv);
      this.isRentAgreement.required = false;
    } else {
      this.showBlock(this.isRentAgreementDiv);
      this.isRentAgreement.required = true;
    }
  }
}
