import { Controller } from "@hotwired/stimulus";
import { show, showBlock, hide } from '../../../utils';

// Reference Financial Damages Form controller
export default class extends Controller {

  connect() {
    this.evictionFiled = this.element.querySelector('select[name="reference[eviction_filed]"]');
    this.evictionNoteDiv = this.element.querySelector('#eviction-note-div');
    this.evictionNote = this.element.querySelector('#reference_eviction_note')
    this.balanceOwed = this.element.querySelector('#reference_balance_owed');
    this.balanceOwedNoteDiv = this.element.querySelector('#balance-owed-note-div');
    this.balanceOwedNote = this.element.querySelector('#reference_balance_owed_note');
    this.propertyDamage = this.element.querySelector('select[name="reference[property_damage]"]');
    this.propertyDamageNoteDiv = this.element.querySelector('#property-damage-note-div');
    this.propertyDamageNote = this.element.querySelector('#reference_property_damage_note');

    this.evictionFiled.addEventListener("change", this.evictionFiledEventListener.bind(this));
    this.balanceOwed.addEventListener("input", this.balanceOwedEventListener.bind(this));
    this.propertyDamage.addEventListener("change", this.propertyDamageEventListener.bind(this));
    this.syncFormWithEvictionFiled();
    this.syncFormWithBalanceOwed();
    this.syncFormWithPropertyDamage();
  }

  evictionFiledEventListener(e) {
    this.syncFormWithEvictionFiled();
  }

  balanceOwedEventListener() {
    this.syncFormWithBalanceOwed();
  }

  propertyDamageEventListener() {
    this.syncFormWithPropertyDamage();
  }

  syncFormWithBalanceOwed() {
    let value = this.balanceOwed.value;
    if (value > 0) {
      showBlock(this.balanceOwedNoteDiv);
      this.balanceOwedNote.required = true;

    } else {
      hide(this.balanceOwedNoteDiv);
      this.balanceOwedNote.required = false;
    }
  }

  syncFormWithPropertyDamage() {
    let value = this.propertyDamage.value;
    if (value === 'MINOR_DAMAGE' || value === 'MAJOR_DAMAGE') {
      showBlock(this.propertyDamageNoteDiv);
      this.propertyDamageNote.required = true;
    } else {
      hide(this.propertyDamageNoteDiv);
      this.propertyDamageNote.required = false;
    }
  }

  syncFormWithEvictionFiled() {
    let value = this.evictionFiled.value;
    if (value === 'true') {
      showBlock(this.evictionNoteDiv);
      this.evictionNote.required = true;
    } else {
      hide(this.evictionNoteDiv);
      this.evictionNote.required = false;
    }
  }
}
