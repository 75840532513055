import Dropzone from "dropzone";
import { Controller } from "@hotwired/stimulus";
import { DirectUpload } from "@rails/activestorage";

// Shamelessy ripped from https://webcrunch.com/posts/rails-drag-drop-active-storage-stimulus-dropzone

export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.dropZone = createDropZone(this);
    this.hideFileInput();
    this.bindEvents();
    const form = this.element.closest("form");
    this.submitButtons = form.querySelectorAll(
      "button[type='submit'][data-disable-on-upload='true'], input[type='submit'][data-disable-on-upload='true']",
    );
  }

  // Private
  hideFileInput() {
    this.inputTarget.disabled = true;
    this.inputTarget.style.display = "none";
  }

  bindEvents() {
    this.dropZone.on("addedfile", (file) => {
      this.disable_submit_buttons();
      setTimeout(() => {
        if (file.accepted !== true) return;
        createDirectUploadController(this, file).start();
      }, 500);
    });

    this.dropZone.on("removedfile", (file) => {
      file.controller && file.controller.hiddenInput.remove();
      this.enable_submit_button_on_accepted();
    });

    this.dropZone.on("canceled", (file) => {
      file.controller && file.controller.xhr.abort();
    });

    this.dropZone.on("success", (file) => {
      this.enable_submit_button_on_accepted();
    });
  }

  disable_submit_buttons() {
    this.submitButtons.forEach((btn) => (btn.disabled = true));
  }

  enable_submit_buttons() {
    this.submitButtons.forEach((btn) => (btn.disabled = false));
  }

  enable_submit_button_on_accepted() {
    if (this.dropZone.files.every((file) => file.accepted)) {
      this.enable_submit_buttons();
    }
  }

  get headers() {
    return { "X-CSRF-Token": document.querySelector("meta[name='csrf-token']") };
  }

  get url() {
    return this.inputTarget.dataset.directUploadUrl;
  }

  get maxFiles() {
    return this.inputTarget.dataset.maxFiles || 1;
  }

  get maxFileSize() {
    return this.inputTarget.dataset.maxFileSize || 256;
  }

  get acceptedFiles() {
    return this.inputTarget.dataset.acceptedFiles;
  }

  get addRemoveLinks() {
    return this.inputTarget.dataset.addRemoveLinks || true;
  }

  get messages() {
    return JSON.parse(this.inputTarget.dataset.messages) || {};
  }

  get uploadMultiple() {
    return this.inputTarget.dataset.uploadMultiple || false;
  }
}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.url, this);
    this.source = source;
    this.file = file;
  }

  start() {
    this.file.controller = this;
    this.hiddenInput = this.createHiddenInput();
    this.directUpload.create((error, attributes) => {
      if (error) {
        this.hiddenInput.parentNode.removeChild(this.hiddenInput);
        this.emitDropzoneError(error);
      } else {
        this.hiddenInput.value = attributes.signed_id;
        this.emitDropzoneSuccess();
      }
    });
  }

  createHiddenInput() {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = this.source.inputTarget.name;
    if (this.source.uploadMultiple == "true") {
      input.name = input.name + "[]";
    }
    this.source.inputTarget.parentNode.insertBefore(input, this.source.inputTarget.nextSibling);
    return input;
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr);
    this.emitDropzoneUploading();
  }

  bindProgressEvent(xhr) {
    xhr.upload.addEventListener("progress", (event) => this.uploadRequestDidProgress(event));
  }

  uploadRequestDidProgress(event) {
    const progress = (event.loaded / event.total) * 100;
    this.file.previewTemplate.querySelector(".dz-upload").style.width = `${progress}%`;
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING;
    this.source.dropZone.emit("processing", this.file);
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.source.dropZone.emit("error", this.file, "Failed to upload file.");
    this.source.dropZone.emit("complete", this.file);
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS;
    this.source.dropZone.emit("success", this.file);
    this.source.dropZone.emit("complete", this.file);
  }
}

function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file);
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller);
}

function accepted_files(file, done) {
  if (file.size == 0) {
    done("Empty files will not be uploaded.");
  } else {
    done();
  }
}

function createDropZone(controller) {
  return new Dropzone(controller.element, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize, // Intentional name "Filesize",
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks,
    uploadMultiple: controller.uploadMultiple,
    autoQueue: false,
    accept: accepted_files,
    ...controller.messages,
  });
}
