import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const invalidChars = ["-", "e", "+", "E"];
    // Prevent exponential's and other characters from being inputted as value
    this.element.addEventListener("keydown", (e) => {
      if (invalidChars.includes(e.key)) {
        e.preventDefault();
      }
    });
  }

  disconnect() {
    this.element.removeEventListener("keydown");
  }
}
