import { Controller } from "@hotwired/stimulus";

// Searchbar controller
export default class extends Controller {
  connect() {
    const searchBars = document.querySelectorAll(".searchbar");

    searchBars.forEach((searchbar) => {
      const searchText = searchbar.querySelector(".searchbar__search_text");
      const searchSubmit = searchbar.querySelector("#search_submit");
      const clearBtn = searchbar.querySelector(".searchbar__clear_search_btn");

      clearBtn.addEventListener("click", (_e) => {
        const globalSearchbar = !!searchbar.querySelector(
          "#global_search_search_text"
        );

        if (clearBtn.dataset.preserveResults) {
          searchText.value = "";
          searchSubmit.click();
        } else if (globalSearchbar) {
          searchText.value = "";
        } else {
          searchText.value && location.reload();
        }
      });
    });
  }
}
