import { Controller } from "@hotwired/stimulus";

// Modal controller
export default class extends Controller {
  connect() {
    const orderForm = document.getElementById("order_form");
    const allowedDuplicate = orderForm.querySelector("input[name='order[allowed_duplicate]'");

    this.element.addEventListener("click", (e) => {
      e.preventDefault();

      allowedDuplicate.value = 1;
      orderForm.submit();
    });
  }
}
