import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const integersOnly = this.element.dataset.integersOnly === "true" || false;
    if (integersOnly) {
      const invalidChars = [".", "-", "e", "+", "E"];
      this.element.addEventListener("keydown", (e) => {
        if (invalidChars.includes(e.key)) {
          e.preventDefault();
        }
      });
    }
  }

  disconnect() {
    this.element.removeEventListener("keydown");
  }
}
