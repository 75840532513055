import { Controller } from "@hotwired/stimulus";

// Reference Form controller
export default class extends Controller {

  connect() {
    this.backBtn = this.element.querySelector("#previous-form-stage");
    this.formSections = this.element.querySelectorAll("div[data-form-section='true']");
    this.priorResidentInputs = this.element.querySelectorAll(".prior_resident_input");
    this.applicantStatus = this.element.querySelector('select[name="reference[applicant_status]"]');
    this.landlordRelationshipDiv = this.element.querySelector('#landlord-relationship-div');
    this.landlordRelationship = this.element.querySelector('select[name="reference[landlord_relationship]"]');
    this.isRentAgreementDiv = this.element.querySelector('#is-rent-agreement-div');
    this.isRentAgreement = this.element.querySelector('select[name="reference[paid_rent]"]');
    this.submitBtn = this.element.querySelector('input[name="commit"]');
    this.nextBtn = this.element.querySelector("#next-form-stage");
    this.adjustAddressDiv = this.element.querySelector("#adjust-address-fields");
    this.adjustAddressBtn = this.element.querySelector("#adjust-address-visibility-on");

    this.backBtn.addEventListener("click", this.backEventListener.bind(this));
    this.nextBtn.addEventListener("click", this.nextEventListener.bind(this));
    this.adjustAddressBtn.addEventListener("click", this.adjustAddressEventListener.bind(this));
    this.applicantStatus.addEventListener("change", this.applicantStatusEventListener.bind(this));
    this.landlordRelationship.addEventListener("change", this.landlordRelationshipEventListener.bind(this));

    this.stage = 0;

    this.syncFormWithResidentStatus(this.applicantStatus.value);
    this.syncFormWithLandlordRelationship(this.landlordRelationship.value);
  }

  hide(element) {
    element.style.setProperty("display", "none");
  }

  show(element) {
    element.style.setProperty("display", "inline-block", "important")
  }

  showBlock(element) {
    element.style.setProperty("display", "block", "important")
  }

  backEventListener(_e) {
    if (this.stage === 0) return;

    this.stage -= 1;

    if (this.stage === 0) {
      this.hide(this.backBtn);
    }

    this.hide(this.submitBtn);
    this.show(this.nextBtn);
    this.hide(this.formSections[this.stage + 1]);
    this.showBlock(this.formSections[this.stage]);
    this.formSections[this.stage].scrollIntoView();
  }

  nextEventListener(_e) {
    if (this.stage === this.formSections.length - 1) return;

    let fields = this.formSections[this.stage].querySelectorAll("input, select")
    for (let i = 0; i < fields.length; i++) {
      if (fields[i].offsetParent !== null && !fields[i].checkValidity()) {
        // Visible and invalid. Prevent advancement until required fields are completed.
        fields[i].reportValidity();
        return;
      }
    }

    this.stage += 1;

    if (this.stage === this.formSections.length - 1) {
      this.showSubmitControl()
    }

    this.show(this.backBtn);
    this.hide(this.formSections[this.stage - 1]);
    this.showBlock(this.formSections[this.stage]);
    this.formSections[this.stage].scrollIntoView();
  }

  adjustAddressEventListener(_e) {
    this.showBlock(this.adjustAddressDiv);
  }

  applicantStatusEventListener(e) {
    this.syncFormWithResidentStatus(e.target.value);
  }

  landlordRelationshipEventListener(e) {
    this.syncFormWithLandlordRelationship(e.target.value);
  }

  disconnect() {
    this.applicantStatus.removeEventListener("change", (e) =>
      this.syncFormWithResidentStatus(e.target.value)
    );
  }

  syncFormWithResidentStatus(value) {
    if (value === 'UNKNOWN_RESIDENT' || value === 'LOST_RECORDS') {
      this.hide(this.landlordRelationshipDiv);
      this.hide(this.adjustAddressBtn);
      this.hide(this.adjustAddressDiv);
      this.landlordRelationship.value = null;
      this.landlordRelationship.required = false;
      this.syncFormWithLandlordRelationship()
      this.showSubmitControl();
      return;
    }

    this.show(this.adjustAddressBtn);
    this.show(this.nextBtn);
    this.hide(this.submitBtn);
    if (!value) return;

    this.showBlock(this.landlordRelationshipDiv);
    this.landlordRelationship.required = true;

    if (value === 'PRIOR_RESIDENT') {
      this.priorResidentInputs.forEach(input => this.showBlock(input));
    } else {
      this.priorResidentInputs.forEach(input => this.hide(input));
      this.clearPriorResidentInputs()
    }
  }

  syncFormWithLandlordRelationship(value) {
    if (!value || value === 'SELF') {
      this.hide(this.isRentAgreementDiv);
      this.isRentAgreement.value = null;
      this.isRentAgreement.required = false;
    } else {
      this.showBlock(this.isRentAgreementDiv);
      this.isRentAgreement.required = true;
    }
  }

  clearPriorResidentInputs() {
    this.priorResidentInputs.forEach(input => input.querySelectorAll("input, select")
      .forEach((elem) => (elem.value = null)));
  }

  showSubmitControl() {
    this.hide(this.nextBtn);
    this.show(this.submitBtn);
  }
}
