import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

// Controller for tom-select input
export default class extends Controller {
  connect() {
    // Disable sorting of options if data attribute 'sort' is false
    const sortFields = () => {
      if (this.element.dataset.sort === "false") {
        return {};
      }

      return {
        sortField: {
          field: "text",
          direction: "asc",
        },
      };
    };

    // Disable searching of input if data attribute 'search' is false
    const disableSearch = () => {
      if (this.element.dataset.search === "false") {
        return { controlInput: null };
      }

      return {};
    };

    // Render custom html to support option descriptors if present
    const render = () => {
      if (this.element.dataset.optionDescriptor === undefined || this.element.dataset.optionDescriptor === null) {
        return {};
      }

      return {
        option: function (data, escape) {
          return `<div>
                   <span class="text">
                     ${escape(data.text)}
                   </span>
                   <span class="description">
                     ${escape(data.description || "")}
                   </span>
                 </div>`;
        },
        item: function (data, escape) {
          return '<div text="' + escape(data.description) + '">' + escape(data.text) + "</div>";
        },
      };
    };

    const config = {
      allowEmptyOption: true,
      ...sortFields(),
      ...disableSearch(),
      render: render(),
    };

    const selectInput = new TomSelect(this.element, config);

    // Resize height of dropdown content if data attribute 'size' is present (currently only supports 100% height)
    if (this.element.dataset.size === "all") {
      selectInput.dropdown_content.classList.add("responsive-content");
    }
  }
}
