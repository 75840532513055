import { Controller } from "@hotwired/stimulus";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";

// intl-tel-input doesn't mention anything in the docs but appears to only work inside of an element
export default class extends Controller {
  static targets = ["phoneInput"];

  phoneInputTargetConnected(element) {
    const iti = this.configurePhoneInput(element);

    const observer = createObserver();
    observer.observe(iti.telInput, {
      attributes: true,
    });

    function createObserver() {
      // Set initial state of disabled on hidden input
      if (element.disabled || element.disabled === "disabled") {
        element.nextElementSibling.disabled = true;
      } else {
        element.nextElementSibling.removeAttribute("disabled");
      }
      return new MutationObserver((mutations) => {
        mutations.forEach(function (mutation) {
          if (mutation.type !== "attributes") return;

          if (mutation.target.disabled || mutation.target.disabled === "disabled") {
            element.nextElementSibling.disabled = true;
          } else {
            element.nextElementSibling.removeAttribute("disabled");
          }
        });
      });
    }
  }

  phoneInputTargetDisconnected(element) {
    element.removeEventListener("input", this.formatNumbersListener);
  }

  configurePhoneInput(input) {
    return intlTelInput(input, {
      allowDropdown: false,
      onlyCountries: ["us"],
      nationalMode: true,
      formatOnDisplay: true,
      initialCountry: "us",
      hiddenInput: (telInputName) => ({ phone: telInputName }),
      utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@24.5.0/build/js/utils.js",
    });
  }

  formatNumbersListener(iti) {
    return (e) => {
      const val = e.target.value.replace(/[^0-9]/g, "");
      iti.setNumber(val);
    };
  }
}
