import { Controller } from "@hotwired/stimulus";
import { TempusDominus } from "@eonasdan/tempus-dominus";
import { Maskito } from "@maskito/core";
import { maskitoDateOptionsGenerator } from "@maskito/kit";

export default class extends Controller {
  connect() {
    const hiddenField = this.element.parentElement.querySelector("input[hidden='hidden']");
    const dataset = hiddenField.dataset;
    const config = {
      display: {
        components: {
          calendar: true,
          date: true,
          month: true,
          year: true,
          decades: true,
          clock: true,
        },
        buttons: {
          today: true,
          clear: true,
          close: true,
        },
      },
      useCurrent: false,
    };

    for (var d in dataset) {
      if (d.slice(0, 2) !== "td") break;

      let strippedName = d.substring(2);
      strippedName = strippedName.charAt(0).toLowerCase() + strippedName.slice(1);

      if (strippedName === "format") {
        config.localization = { [strippedName]: dataset[d] };
        config.display.components.clock = false;
        break;
      }

      config[strippedName] = dataset[d];
    }

    const maskitoOptions = maskitoDateOptionsGenerator({
      mode: "mm/dd/yyyy",
      separator: "/",
      // TODO: Set up min maxes? Can be set by either input or type of input (eg. DOB)
      // min: new Date(1900, 0, 1),
      // max: new Date(),
    });
    new Maskito(this.element, maskitoOptions);
    const picker = new TempusDominus(this.element, config);

    this.element.addEventListener("input", (e) => {
      if (e.target.value.length == 10) {
        const dateInUtc = new Date(e.target.value.replace(/-/, "/").replace(/-/, "/"));
        picker.dates.setFromInput(dateInUtc);
      }
    });

    if (hiddenField.value) {
      const dateInUtc = new Date(hiddenField.value.replace(/-/, "/").replace(/-/, "/")); // HACK: JS DateTime will respect UTC in this format
      picker.dates.setFromInput(dateInUtc);
    }

    this.element.addEventListener("change.td", (_e) => {
      hiddenField.value = new Date(this.element.value).toUTCString();
    });
  }
}
