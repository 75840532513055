import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const statusInput = this.element.querySelector('select[name="reference[status]"]');
    const flaggedReasonInput = this.element.querySelector('select[name="reference[flagged_reason]"]');
    statusInput.addEventListener("change", toggleFlaggedReasonVisibility);
    if (statusInput.value === "FLAGGED") {
      flaggedReasonInput.parentElement.classList.remove("d-none");
      flaggedReasonInput.required = true;
    } else {
      flaggedReasonInput.value = null;
      flaggedReasonInput.parentElement.classList.add("d-none");
      flaggedReasonInput.required = false;
    }

    function toggleFlaggedReasonVisibility(e) {
      if (e.target.value === "FLAGGED") {
        flaggedReasonInput.parentElement.classList.remove("d-none");
        flaggedReasonInput.required = true;
      } else {
        flaggedReasonInput.value = null;
        flaggedReasonInput.parentElement.classList.add("d-none");
        flaggedReasonInput.required = false;
      }
    }
  }
}
