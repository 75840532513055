import { Controller } from "@hotwired/stimulus";

// References Action Center initial verification stage form controller
export default class extends Controller {
  connect() {
    console.log("connected");
    const backBtn = this.element.querySelector('button[name="last_form_stage"]');
    const nextBtn = this.element.querySelector('button[name="next_form_stage"]');
    const submitBtn = this.element.querySelector('button[type="submit"]');
    const formSections = this.element.querySelectorAll("div[data-form-section='true']");

    let stage = 0;

    backBtn &&
      backBtn.addEventListener("click", (_e) => {
        if (stage === 0) return;

        if (stage - 1 === 0) {
          backBtn.setAttribute("data-bs-dismiss", "modal");
          backBtn.setAttribute("data-aria-label", "Close");
        }

        stage -= 1;

        submitBtn.style.setProperty("display", "none");
        nextBtn.style.setProperty("display", "inline-block", "important");
        formSections[stage + 1].style.setProperty("display", "none");
        formSections[stage].style.setProperty("display", "block", "important");
        formSections[stage].scrollIntoView();
      });

    nextBtn &&
      nextBtn.addEventListener("click", (_e) => {
        if (stage === formSections.length - 1) return;

        if (stage === 0) {
          backBtn.removeAttribute("data-bs-dismiss");
          backBtn.removeAttribute("data-aria-label");
          console.log(backBtn.dataset);
        }

        stage += 1;

        if (stage === formSections.length - 1) {
          nextBtn.style.setProperty("display", "none");
          submitBtn.style.setProperty("display", "inline-block", "important");
        }

        backBtn.style.setProperty("display", "inline-block", "important");
        formSections[stage - 1].style.setProperty("display", "none");
        formSections[stage].style.setProperty("display", "block", "important");
        formSections[stage].scrollIntoView();
      });
  }
}
