import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const landlordRelationshipSelectInput = this.element.querySelector("#reference_landlord_relationship");
    const nonSelfFields = this.element.querySelector("#applicant_form_non_self_fields");
    const nonSelfAdditionalFields = this.element.querySelector("#applicant_form_non_self_additional_fields");
    const selfAdditionalFields = this.element.querySelector("#applicant_form_self_additional_fields");

    // Set initial visibility
    visibilitySwitch(landlordRelationshipSelectInput.value);
    landlordRelationshipSelectInput.addEventListener("change", (e) => {
      // Change visibility on input change
      visibilitySwitch(e.target.value);
    });

    function visibilitySwitch(value) {
      switch (value) {
        case "":
          toggleAllFieldsVisibility();
          break;
        case "SELF":
          toggleSelfFieldsVisibility();
          break;
        default:
          toggleNonSelfFieldsVisibility();
      }
    }

    function toggleAllFieldsVisibility() {
      nonSelfFields.hidden = true;
      nonSelfAdditionalFields.hidden = true;
      selfAdditionalFields.hidden = true;
    }

    function toggleNonSelfFieldsVisibility() {
      nonSelfFields.hidden = false;
      nonSelfAdditionalFields.hidden = false;
      selfAdditionalFields.hidden = true;
    }

    function toggleSelfFieldsVisibility() {
      nonSelfFields.hidden = true;
      nonSelfAdditionalFields.hidden = true;
      selfAdditionalFields.hidden = false;
    }
  }
}
