import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.contactMethodSelect = this.element.querySelector("#outreach_event_contact_method");
    this.toggleScriptFormVisibility()
    this.toggleSendReferenceInitialOutreach()
    this.toggleCallResultSelectVisibility()
    this.setupDropdownChoiceManagement()
    this.setupAttachedFileDropzoneManagement()
    this.setupTemplatedEventsExperience()
  }

    templatedEvents() {
    return ["EMAIL", "TEXT"];
  }

  toggleScriptFormVisibility() {
    const scriptFormContainer = this.element.querySelector("#outreach_events_script_form_container");
    if (!scriptFormContainer) return;
    const hideScriptContainer = () => scriptFormContainer.hidden = this.contactMethodSelect.value !== 'CALL'

    hideScriptContainer()
    this.contactMethodSelect.addEventListener("change", (e) => {
      hideScriptContainer()
    });
  }

  setupTemplatedEventsExperience() {
    let contactMethodDropdown = document.getElementById('outreach_event_contact_method');
    let directionDropdown = document.getElementById('outreach_event_direction');
    let templateDropdown = document.getElementById('outreach_event_template_used');
    let outcomeOptionsDropdown = document.getElementById('outreach_event_outcome');
    const templatedEvents = ["EMAIL", "TEXT"];

    function getRenderedMessageIds() {
      let element = document.getElementById('rendered-message-ids');
      let renderedMessageIds = JSON.parse(element.getAttribute('data-rendered-message-ids'));
      return renderedMessageIds;
    }

    function getTemplateMetadata() {
      let element = document.getElementById('message-template-metadata');
      let templateMetadata = JSON.parse(element.getAttribute('data-message-template-metadata'));
      return templateMetadata;
    }

    function toggleTemplatedEventsExperience() {
      let contactMethodValue = contactMethodDropdown.value;
      let directionValue = directionDropdown.value;
      if (templatedEvents.includes(contactMethodValue) && directionValue === 'OUTBOUND') {
        document.getElementById('templated-events-experience').hidden = false;
        templateDropdown.required = true;
      } else {
        document.getElementById('templated-events-experience').hidden = true;
        templateDropdown.required = false;
        templateDropdown.value = null;
      }
    }

    function showCorrectMessageTemplate() {
      let templateValue = templateDropdown.value;
      let renderedMessageIds = getRenderedMessageIds();
      let idToShow = templateValue;
      for (let i = 0; i < renderedMessageIds.length; i++) {
        if (renderedMessageIds[i] === idToShow) {
          document.getElementById(renderedMessageIds[i]).hidden = false;
        } else {
          document.getElementById(renderedMessageIds[i]).hidden = true;
        }
      }
    }

    function selectCorrectOutcomeBasedOnTemplateUsed() {
      let templateValue = templateDropdown.value;
      let templateMetdata = getTemplateMetadata();
      if (templateMetdata[templateValue] && templateMetdata[templateValue].expected_outcome) {
        outcomeOptionsDropdown.value = templateMetdata[templateValue].expected_outcome;
      }
    }

    templateDropdown.addEventListener("change", (e) => {
      showCorrectMessageTemplate();
      selectCorrectOutcomeBasedOnTemplateUsed();
    });
    contactMethodDropdown.addEventListener("change", (e) => {
      showCorrectMessageTemplate();
      toggleTemplatedEventsExperience();
    });
    directionDropdown.addEventListener("change", (e) => {
      showCorrectMessageTemplate();
      toggleTemplatedEventsExperience();
    });
    showCorrectMessageTemplate();
    toggleTemplatedEventsExperience();
    selectCorrectOutcomeBasedOnTemplateUsed();
  }

  toggleSendReferenceInitialOutreach() {
    const referenceEmailInput = this.element.querySelector("#reference_email");
    const phoneInput = this.element.querySelector("#reference_phone");
    const smsEnabledInput = this.element.querySelector("#reference_phone_sms_enabled");
    const sendContactEmailCheckbox = this.element.querySelector("#send_reference_contact_email");
    const toggleSendInitialOutreach = () => {
      let email = document.getElementById('reference_email').value;
      let phoneNumber = document.getElementById('reference_phone').value;
      let smsEnabled = document.getElementById('reference_phone_sms_enabled').value;
      sendContactEmailCheckbox.checked = false;
      sendContactEmailCheckbox.disabled = !(email || (phoneNumber && smsEnabled === 'true'));
    }
    referenceEmailInput.addEventListener("input", (e) => {
      toggleSendInitialOutreach();
    });
    phoneInput.addEventListener("input", (e) => {
      toggleSendInitialOutreach();
    })
    smsEnabledInput.addEventListener("change", (e) => {
      toggleSendInitialOutreach();
    })
    this.contactMethodSelect.addEventListener("change", (_e) => {
      toggleSendInitialOutreach();
    });
  }

  toggleCallResultSelectVisibility() {
    const callResultSelect = this.element.querySelector('#outreach_event_contact_result')
    const callResultSelectWrapper =  this.element.querySelector('#outreach_event_contact_result_select_wrapper')
    if (!callResultSelect || !callResultSelectWrapper) return;

    toggleVisibility(this.contactMethodSelect.value)
    this.contactMethodSelect.addEventListener("change", (e) => {
      toggleVisibility(e.target.value)
    });

    function toggleVisibility(value) {
      if (value == 'CALL') {
        callResultSelectWrapper.hidden = false
        callResultSelect.required = true
      } else {
        callResultSelectWrapper.hidden = true
        callResultSelect.selectedIndex = 0
        callResultSelect.required = false
      }
    }
  }

  setupDropdownChoiceManagement() {
    let contactMethodDropdown = document.getElementById('outreach_event_contact_method');
    let directionDropdown = document.getElementById('outreach_event_direction');
    let contactResultDropdown = document.getElementById('outreach_event_contact_result');
    let outcomeOptionsDropdown = document.getElementById('outreach_event_outcome');
    contactResultDropdown.addEventListener("change", (e) => {
      showOptionsInOutcomeDropdown(getOutcomeDropdownOptions())
    });
    contactMethodDropdown.addEventListener("change", (e) => {
      showOptionsInOutcomeDropdown(getOutcomeDropdownOptions())
    });
    directionDropdown.addEventListener("change", (e) => {
      showOptionsInOutcomeDropdown(getOutcomeDropdownOptions())
    })

    function showOptionsInOutcomeDropdown(options) {
      for (let i = 0; i < outcomeOptionsDropdown.options.length; i++) {
        if (outcomeOptionsDropdown.options[i].value in options) {
          outcomeOptionsDropdown.options[i].hidden = false;
        } else {
          outcomeOptionsDropdown.options[i].hidden = true;
        }
      }
      outcomeOptionsDropdown.value = null;
    }

    function getOutcomeDropdownOptions() {
      const element = document.getElementById('outcomes-options');
      const outcomesOptions = JSON.parse(element.getAttribute('data-outcomes'));
      if (contactMethodDropdown.value in outcomesOptions && directionDropdown.value in outcomesOptions[contactMethodDropdown.value]) {
        if (contactResultDropdown.value in outcomesOptions[contactMethodDropdown.value][directionDropdown.value]) {
          return outcomesOptions[contactMethodDropdown.value][directionDropdown.value][contactResultDropdown.value];
        } else {
          return outcomesOptions[contactMethodDropdown.value][directionDropdown.value]['DEFAULT'];
        }
      }
      return [];
    }
    // Run the method once when setting up to work for cases when we auto-set the selection
    showOptionsInOutcomeDropdown(getOutcomeDropdownOptions());
  }

  setupAttachedFileDropzoneManagement() {
    let outcomeOptionsDropdown = document.getElementById('outreach_event_outcome');
    let dropzone = document.getElementById('attached_file_dropzone');
    outcomeOptionsDropdown.addEventListener("change", (e) => {
      manageDropzone(e.target.value);
    });

    manageDropzone(outcomeOptionsDropdown.value);

    function manageDropzone(selectedOutcome) {
      if (selectedOutcome == "RECEIVED_LEDGER") {
        showDropzone();
      } else {
        hideDropzone();
      }
    }

    function showDropzone() {
      dropzone.hidden = false;
    }

    function hideDropzone() {
      dropzone.hidden = true;
    }
  }
}
