import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const controllerEnabled = this.element.dataset.controllerEnabled == "true";
    if (!controllerEnabled) return;

    this.scriptFormContainer = this.element.querySelector("#outreach_events_script_form_container");
    this.referenceScriptForm = this.element.querySelector("#outreach_reference_script_form");
    this.eventContactMethodSelect = this.element.querySelector("#outreach_event_contact_method");
    this.templatedCheckbox = this.element.querySelector("#outreach_event_templated");
    this.referenceEmailInput = this.element.querySelector("#reference_email");
    this.sendContactEmailCheckbox = this.element.querySelector("#send_reference_contact_email");

    this.toggleScriptFormVisibility(this.eventContactMethodSelect.value);

    this.eventContactMethodSelect.addEventListener("change", (e) => {
      this.toggleScriptFormVisibility(e.target.value);
      this.toggleTemplatedCheckboxVisibility(e.target.value);
    });

    if (this.sendContactEmailCheckbox) {
      this.sendContactEmailCheckbox.disabled = !this.referenceEmailInput.value;
    }
    this.referenceEmailInput.addEventListener("input", (e) => {
      this.toggleSendContactEmailDisabled(e.target.value);
    });
  }

  toggleScriptFormVisibility(contact_method) {
    this.scriptFormContainer.hidden = contact_method !== "CALL";
  }

  templatedEvents() {
    return ["EMAIL", "TEXT"];
  }

  showReferenceCallScript() {
    this.referenceScriptForm.hidden = true;
    this.referenceYearVerifiedWrapper.hidden = false;
  }

  toggleTemplatedCheckboxVisibility(contact_method) {
    const templatedEvent = this.templatedEvents().includes(contact_method);
    this.templatedCheckbox.closest("fieldset").hidden = !templatedEvent; // Show checkbox if templated event

    if (!templatedEvent) {
      this.templatedCheckbox.checked = false; // Reset to false if changed to non-templatable event
    }
  }

  toggleSendContactEmailDisabled(value) {
    if (!this.sendContactEmailCheckbox) return;

    this.sendContactEmailCheckbox.checked = false;
    this.sendContactEmailCheckbox.disabled = !value;
  }
}
