import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const expressSubmitBtn = this.element.querySelector("#new_order_express_submit");
    const manualSubmitBtn = this.element.querySelector("#new_order_manual_submit");
    const submissionType = this.element.querySelector("#order_express_submission");

    // If manual submit button was clicked set express_submission to false
    manualSubmitBtn?.addEventListener("click", (_e) => {
      submissionType.value = false;
    });

    // If express submit button was clicked set express_submission to true
    expressSubmitBtn?.addEventListener("click", (_e) => {
      submissionType.value = true;
    });
  }
}
