import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.scriptFormContainer = this.element.querySelector("#outreach_events_script_form_container");
    this.applicantScriptForm = this.element.querySelector("#outreach_applicant_script_form");
    this.eventContactMethodSelect = this.element.querySelector("#outreach_event_contact_method");
    this.templatedCheckbox = this.element.querySelector("#outreach_event_templated");
    this.applicantEmailInput = this.element.querySelector("#applicant_reference_report_email");
    this.phoneInput = this.element.querySelector("#applicant_reference_report_phone");
    this.smsEnabledInput = this.element.querySelector("#applicant_reference_report_phone_sms_enabled");
    this.sendContactEmailCheckbox = this.element.querySelector("#send_applicant_initial_outreach");

    this.toggleScriptFormVisibility();
    this.toggleCallResultSelectVisibility();
    this.toggleSendApplicantInitialOutreach();
    this.setupDropdownChoiceManagement();
    this.setupTemplatedEventsExperience();
  }

  toggleScriptFormVisibility() {
    const toggleVisibility = (contact_method) => {
      const callEvent = contact_method === "CALL";
      this.scriptFormContainer.hidden = !callEvent;
    }

    toggleVisibility(this.eventContactMethodSelect.value);
    this.eventContactMethodSelect.addEventListener("change", (e) => {
      toggleVisibility(e.target.value);
    });
  }

  templatedEvents() {
    return ["EMAIL", "TEXT"];
  }

  setupTemplatedEventsExperience() {
    let contactMethodDropdown = document.getElementById('outreach_event_contact_method');
    let directionDropdown = document.getElementById('outreach_event_direction');
    let templateDropdown = document.getElementById('outreach_event_template_used');
    let outcomeOptionsDropdown = document.getElementById('outreach_event_outcome');
    let referenceDropdown = document.getElementById('reference-dropdown');
    const templatedEvents = ["EMAIL", "TEXT"];

    function getRenderedMessageIds() {
      let element = document.getElementById('rendered-message-ids');
      let renderedMessageIds = JSON.parse(element.getAttribute('data-rendered-message-ids'));
      return renderedMessageIds;
    }

    function getTemplateMetadata() {
      let element = document.getElementById('message-template-metadata');
      let templateMetadata = JSON.parse(element.getAttribute('data-message-template-metadata'));
      return templateMetadata;
    }

    function toggleReferencePickerBasedOnTemplate() {
      let templateValue = templateDropdown.value;
      let templateMetadata = getTemplateMetadata();
      if (templateMetadata[templateValue] && templateMetadata[templateValue].requires_reference) {
        document.getElementById('reference-picker').hidden = false;
      } else {
        document.getElementById('reference-picker').hidden = true;
      }
    }

    function toggleTemplatedEventsExperience() {
      let contactMethodValue = contactMethodDropdown.value;
      let directionValue = directionDropdown.value;
      if (templatedEvents.includes(contactMethodValue) && directionValue === 'OUTBOUND') {
        document.getElementById('templated-events-experience').hidden = false;
        templateDropdown.required = true;
      } else {
        document.getElementById('templated-events-experience').hidden = true;
        templateDropdown.required = false;
        templateDropdown.value = null;
      }
    }

    function showCorrectMessageTemplate() {
      let templateValue = templateDropdown.value;
      let referenceValue = referenceDropdown.value;
      let renderedMessageIds = getRenderedMessageIds();
      let idToShow = templateValue + "_" + referenceValue;
      for (let i = 0; i < renderedMessageIds.length; i++) {
        if (renderedMessageIds[i] === idToShow) {
          document.getElementById(renderedMessageIds[i]).hidden = false;
        } else {
          document.getElementById(renderedMessageIds[i]).hidden = true;
        }
      }
    }

    function selectCorrectOutcomeBasedOnTemplateUsed() {
      let templateValue = templateDropdown.value;
      let templateMetdata = getTemplateMetadata();
      if (templateMetdata[templateValue] && templateMetdata[templateValue].expected_outcome) {
        outcomeOptionsDropdown.value = templateMetdata[templateValue].expected_outcome;
      }
    }

    templateDropdown.addEventListener("change", (e) => {
      showCorrectMessageTemplate();
      toggleReferencePickerBasedOnTemplate();
      selectCorrectOutcomeBasedOnTemplateUsed();
    });
    referenceDropdown.addEventListener("change", (e) => {
      showCorrectMessageTemplate();
    });
    contactMethodDropdown.addEventListener("change", (e) => {
      showCorrectMessageTemplate();
      toggleTemplatedEventsExperience();
    });
    directionDropdown.addEventListener("change", (e) => {
      showCorrectMessageTemplate();
      toggleTemplatedEventsExperience();
    });
    showCorrectMessageTemplate();
    toggleReferencePickerBasedOnTemplate();
    toggleTemplatedEventsExperience();
    selectCorrectOutcomeBasedOnTemplateUsed();
  }

  toggleSendApplicantInitialOutreach() {
    const toggleSendInitialOutreach = () => {
      let email = document.getElementById('applicant_reference_report_email').value;
      let phoneNumber = document.getElementById('applicant_reference_report_phone').value;
      let smsEnabled = document.getElementById('applicant_reference_report_phone_sms_enabled').checked;
      this.sendContactEmailCheckbox.checked = false;
      this.sendContactEmailCheckbox.disabled = !(email || (phoneNumber && smsEnabled));
    }
    this.applicantEmailInput.addEventListener("input", (e) => {
      toggleSendInitialOutreach();
    });
    this.phoneInput.addEventListener("change", (e) => {
      toggleSendInitialOutreach();
    })
    this.smsEnabledInput.addEventListener("change", (e) => {
      toggleSendInitialOutreach();
    })
    this.eventContactMethodSelect.addEventListener("change", (_e) => {
      toggleSendInitialOutreach();
    });
  }

  toggleCallResultSelectVisibility() {
    const callResultSelect = this.element.querySelector('#outreach_event_contact_result')
    const callResultSelectWrapper =  this.element.querySelector('#outreach_event_contact_result_select_wrapper')
    if (!callResultSelect || !callResultSelectWrapper) return;

    toggleVisibility(this.eventContactMethodSelect.value)
    this.eventContactMethodSelect.addEventListener("change", (e) => {
      toggleVisibility(e.target.value)
    });

    function toggleVisibility(value) {
      if (value == 'CALL') {
        callResultSelectWrapper.hidden = false
        callResultSelect.required = true
      } else {
        callResultSelectWrapper.hidden = true
        callResultSelect.selectedIndex = 0
        callResultSelect.required = false
      }
    }
  }

  setupDropdownChoiceManagement() {
    let contactMethodDropdown = document.getElementById('outreach_event_contact_method');
    let directionDropdown = document.getElementById('outreach_event_direction');
    let contactResultDropdown = document.getElementById('outreach_event_contact_result');
    let outcomeOptionsDropdown = document.getElementById('outreach_event_outcome');
    contactResultDropdown.addEventListener("change", (e) => {
      showOptionsInOutcomeDropdown(getOutcomeDropdownOptions())
    });
    contactMethodDropdown.addEventListener("change", (e) => {
      showOptionsInOutcomeDropdown(getOutcomeDropdownOptions())
    });
    directionDropdown.addEventListener("change", (e) => {
      showOptionsInOutcomeDropdown(getOutcomeDropdownOptions())
    })

    function showOptionsInOutcomeDropdown(options) {
      for (let i = 0; i < outcomeOptionsDropdown.options.length; i++) {
        if (outcomeOptionsDropdown.options[i].value in options) {
          outcomeOptionsDropdown.options[i].hidden = false;
        } else {
          outcomeOptionsDropdown.options[i].hidden = true;
        }
      }
      outcomeOptionsDropdown.value = null;
    }

    function getOutcomeDropdownOptions() {
      const element = document.getElementById('outcomes-options');
      const outcomesOptions = JSON.parse(element.getAttribute('data-outcomes'));
      if (contactMethodDropdown.value in outcomesOptions && directionDropdown.value in outcomesOptions[contactMethodDropdown.value]) {
        if (contactResultDropdown.value in outcomesOptions[contactMethodDropdown.value][directionDropdown.value]) {
          return outcomesOptions[contactMethodDropdown.value][directionDropdown.value][contactResultDropdown.value];
        } else {
          return outcomesOptions[contactMethodDropdown.value][directionDropdown.value]['DEFAULT'];
        }
      }
      return [];
    }
    // Run the method once when setting up to work for cases when we auto-set the selection
    showOptionsInOutcomeDropdown(getOutcomeDropdownOptions());
  }
}
